import {TRANSLATION_NL} from 'src/environments/wave/translations/nl';
import {TRANSLATION_DE} from 'src/environments/wave/translations/de';
import {TRANSLATION_EN} from 'src/environments/wave/translations/en';
import {TRANSLATION_TR} from 'src/environments/wave/translations/tr';
import {TRANSLATION_PL} from 'src/environments/wave/translations/pl';
import {TRANSLATION_RO} from 'src/environments/wave/translations/ro';
import {TRANSLATION_PT} from 'src/environments/wave/translations/pt';

export const environment = {
    production: true,
    id: 'nl.recognize.wave.acc',
    name: 'WAVE',
    version: '4.7.0',
    author: {
        name: 'Koninklijke VolkerWessels N.V.',
        email: 'info@volkerwessels.com',
        website: 'https://www.volkerwessels.com/',
    },
    deprecation: {
        domains: [],
        date: '2099-09-01T00:00:00',
    },
    host: 'vwwave',
    storage: '__wave',
    initialTheme: '',
    color: {
        primary: '#32659C',
        statusBar: {
            default: '#336699',
            overlay: '#14293D',
        },
    },
    supportedLanguages: [
        {
            value: 'nl',
            label: 'Nederlands',
            translationFile: TRANSLATION_NL,
        },
        {
            value: 'de',
            label: 'Deutsch',
            translationFile: TRANSLATION_DE,
        },
        {
            value: 'en',
            label: 'English',
            translationFile: TRANSLATION_EN,
        },
        {
            value: 'tr',
            label: 'Türkçe',
            translationFile: TRANSLATION_TR,
        },
        {
            value: 'pl',
            label: 'Polsku',
            translationFile: TRANSLATION_PL,
        },
        {
            value: 'ro',
            label: 'Romana',
            translationFile: TRANSLATION_RO,
        },
        {
            value: 'pt',
            label: 'Portugues',
            translationFile: TRANSLATION_PT,
        },
    ],
    api: 'https://vwwave.acc.recognize.hosting',
    apiTimeout: 60000,
    msAuth: {
        tenantId: 'common',
        clientId: '55208627-79e9-470d-b96e-39084973f880',
        keystoreHash: 'R/TKFR6edV2pPRyXSR0M5Hcpyuc=',
        scopes: ['api://55208627-79e9-470d-b96e-39084973f880/default'],
        redirectUri: 'https://vwwave-app.acc.recognize.hosting',
    },
    deeplinks: [
        'https://vwwave.acc.recognize.hosting/deeplink/',
        'https://vwwave-app.acc.recognize.hosting/deeplink/',
    ],
    whitelistedDomains: [
        'vwwave.acc.recognize.hosting',
        'vwwave-app.acc.recognize.hosting',
    ],
    privacyPolicyUrl: 'https://www.volkerwessels.com/privacyverklaring_WAVE-app',
    instructionFilmUrl: 'https://www.volkerwessels.com/veiligheid/wave_app',
    applicationInsights: {
        instrumentationKey: '6513ce48-26c2-4acf-b047-658bd15dd659',
    },
};
